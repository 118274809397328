
  .ec-main-card{
       margin-top: 20px;
       overflow-x: hidden;
  }
  .ec-date-div{
    margin-top: 55px;
  }
  .ec-enddate-div{
    margin-top: 25px;
  }
  .datapicker-sizing{
    width: 100%;
  }

  .ec-enddate-label{
    width: 50px !important;
    margin-left: -19px !important;
    margin-top: 8px;
  }
  .ec-label {
    margin-top: 8px;
  }
  .ec-locatiion-label{
      width:91px ;
      margin-left: -19px;
  }
.ec-back-btn{
  margin-right: 18px;
  right: 0;
  position: absolute;
  margin-top: 24px;
}
    #draw_line{
        color: black;
        border-left: 1px solid #000;
    }
    .chip-body {
      height: 34px !important;
    }
 

    #event-calender-panel {
      height: 306px;
      margin-top: 32px;
      margin-left: 273px;
      width: 355px;
      border-width: 2px;
      border-style: solid;
      border-color: rgb(197, 195, 195);
    }
    #form-panel {
      max-height: 537px;
      height: 535px;
      width: 900px;
      overflow-y: auto;
      margin-left: 30px;
      margin-bottom: 15px;
      margin-top: 10px;
      font-weight: 200px;
      border-width: 2px;
      border-style: solid;
      border-color: rgb(197, 195, 195);
    }
    #event-detials-panel{
      height: 227px;
      margin-top: 1px;
      margin-left: 273px;
      width: 355px;
      border-width: 2px;
      border-style: solid;
      border-color: rgb(197, 195, 195);
    }
    .event-detials-container label{
      font-size: 10px !important;
      margin-left: 10px;
      color: gray !important;
    }
    .errorMessage {
      color: red;
      font-size: 0.75em;
      display: relative;
    }
    .custom-dev{
      padding-top: 15px;
      padding-left: 15px;
    
    }
    .main-event-closure{
      width : 100%;
      height: 100%;
      background: #DCDCDC	;
      margin-top : 90px;
      position : relative;
      margin-bottom: -10px;

    }
    
    .main-event-closure label {
      font-size: 12px;
      color: gray;
    }
    
    .personel-div-closure
    {
      border: 1px solid lightgray;
      height: 40px;
      align-content:  center;
      margin-left: 16px;
      margin-top: 10px;
      
    }
    .personel-div-closure button{
      margin-top: 5px;
      font-size: 10px;
      margin-left: 31px  !important; 
      margin-right: 1px !important;
    }
    .save-btn{
      font-size: 12px;
      width: 146px;
    }
    
    .subject-info-box-1,
    .subject-info-box-2 {
        float: left;
        width: 45%;
    }
    .subject-info-box-2 select {
        height: 200px;
        padding: 0;
    }
    .subject-info-box-2 select  option {
      padding: 4px 10px 4px 10px;
    }
    .subject-info-box-2 select option:hover {
      background: #EEEEEE;
    }
    
    .subject-info-arrows {
        float: left;
        width: 10%;
    }
    .subject-info-arrows  input {
      width: 70%;
      margin-bottom: 5px;
    }
    #searchInput {
      background-position: 10px 10px;
      background-repeat: no-repeat;
      width: 100%;
      font-size: 12px;
      padding: 12px 20px 12px 40px;
      border: 1px solid #ddd;
      margin-bottom: 12px;
    }

    #serviceTable {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #ddd;
      font-size: 12px;
      color: gray;
      margin-top: -36px;
    }

    #assetsTable{
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #ddd;
      font-size: 12px;
      color: gray;
    }
    
    #serviceTable th, #serviceTable td {
      text-align: left;
      padding: 12px;
    }
    
    #serviceTable tr {
      border-bottom: 1px solid #ddd;
    }
     .ec-col{
      margin-left: 10px;
     }
     .ec-col-n{
      margin-top: 78px;
      margin-left: -18px;
     }
     .multiSelect {
      width: 100%;
     }
    
    #serviceTable tr.header, #serviceTable tr:hover {
      background-color: white;
    }

    #assetsTable th, #assetsTable td {
      text-align: left;
      padding: 12px;
    }
    
    #assetsTable tr {
      border-bottom: 1px solid #ddd;
    }

    
    #assetsTable tr.header, #assetsTable tr:hover {
      background-color: white;
    }
  .ec-save-btn-row{
    margin-top: 6px;
    /* margin-left: 33px; */
    right: 0;
    position: absolute;
  }
  .ec-rows{
    margin-top: 32px;
  }
    .label-heading{
      font-size: 16px !important;
      font-weight: bold;
    }
    .label-heading-assets{
      font-size: 16px !important;
      /* margin-top: 16px; */
      font-weight: bold;
    }
    .search_icon{
      position: relative;
      top: -45px;
      margin-left: 10px;
      z-index: 2;
      color: gray;
    }
.calendar_icon{
  position: absolute;
  top: 8px;
  margin-left: -18px;
    z-index: 2;
    color:var(--theme-secondary-color);
}

    #serviceTable tbody {
      display:contents;
      max-height:100px;
      overflow-y:auto;
    }
    .assets_quantity{
      background: #cccccc33;
      width: 72px;
      border: 1px solid;
      height: 20px;
      border-color: #348AA9;
    }
    .assets_quantity_check {
      background: #cccccc33;
      width: 72px;
      border: 1px solid;
      height: 15px;
      border-color: #348AA9;
    }
    .closure-notes{
      margin-bottom: 30px;
    }
    .ec-save-btn-row{
      margin-top: 30px;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    .adjustment{
      margin-left: 2px
    }
    .vertical_adjustment{
      margin-left: 8px;
      margin-right: 8px;
    }