:root {
  --theme-background-color: linear-gradient(to right, rgb(66, 88, 208), rgb(66, 88, 208));
  --theme-icon: rgb(66, 88, 208);
  --theme-button-color: rgb(66, 88, 208);
  --theme-secondary-color: rgb(246, 112, 29);
  --theme-login-input-icon: rgb(66, 88, 208);
  --theme-white-label-color: white;
  --theme-primary-label-color: rgb(66, 88, 208);
  --theme-border-color: rgb(66, 88, 208);
  --theme-button-disable-color: #2C3E50;
  --bg: rgb(66, 88, 208);
  ;
  --text-color: #555;
  --link-color: #639A67;
  --link-hover: #205D67;
  --label-color: rgb(66, 88, 208);
  --primary: rgb(66, 88, 208) !important;
  --secondary: #6c757d;
  --darkgreen: 	rgb(0,100,0);
  --maroon: rgb(128,0,0);
  --orange:rgb(255,165,0);
  --crimson:	rgb(220,20,60);
  --indigo:	rgb(75,0,130);
}

.btn-primary {
  color: #fff;
  background-color: var(--theme-button-color) !important;
  border-color: var(--theme-border-color) !important;
}

.header_title{
  position: relative;
  font-family: Lato;
  font-weight: 200;
  display: inline-block;
  color: #222;
  /* font-size:40px; */
  margin-top: 30px;
  /* margin-left: 114px; */
  border-left: solid 2px var(--theme-secondary-color);
  border-right: solid 2px var(--theme-secondary-color);
  padding: 0 10px;
}

.header_title:before, .header_title:after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--theme-secondary-color);
  width: 30px;
  bottom: 12px
}

.header_title:before {
  left: -30px
}

.header_title:after {
  right: -30px
}

.back-arrow {
  padding: 12px;
}

img path {
  fill: var(--theme-secondary-color);
}

svg:hover {
  fill: var(--theme-secondary-color);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--label-color);
  ;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--theme-secondary-color);
}

body {
  overflow-x: hidden;
}

.react-confirm-alert-body {
  box-shadow: 9px 3px 17px 1px var(--label-color) !important;
  color: #000000;
}

.react-confirm-alert-button-group>button {
  background: var(--theme-background-color) !important;
}

.required:after {
  content: " *";
  color: red;
}

.MuiIconButton-colorSecondary{
  color:var(--bg)!important;
}
/* .form-group.required .col-form-label:after {
  content:"*" !important;
  color:red !important;
} */
