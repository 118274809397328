.ep-col {
  margin-left: 10px;
}

.ep-col-n {
  margin-top: 16px;
  margin-left: -18px;
}

.ep-heading {
  margin-left: 35px;
}

.ep-label-heading {
  font-size: 16px !important;
  font-weight: bold;
  margin-left: 13px;
}
.ep_service{
  margin-bottom: 12px;
}

.ep-back-btn {
  width: 85px;
  /* margin-bottom: 4px; */
  /* margin-left: 458px; */
  margin-right: 18px;
  right: 0;
  position: absolute;
  margin-top: 24px;
}

.ep-main-card-services {
  margin-top: 61px;
}
.space{
  margin-right: 1px;
}

.ep-save-btn-row {
  width: 100px;
  /* margin-top: 6px; */
  /* margin-left: 33px; */
  /* right: 0;
  position: absolute; */
}

.makeStyles-list-32 {
  height: 179px !important;
}

.ep-main-card {
  margin-top: 20px;
  /* height: 100% !important; */
  overflow-y: auto;
  overflow-x: hidden;
}

.ep-label-heading-assets {
  font-size: 16px !important;
  font-weight: bold;
  margin-left: 13px;
  margin-top: 10px;
}

.ep-expansion {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.ep-transferlist {
  margin-left: -43px;
}

.chip-text {
  margin: 0;
  display: inline;
  color: white !important;
}

#draw_line {
  color: black;
  border-left: 1px solid #000;
}

.multiSelect {
  margin-left: -1px !important;
}

.makeStyles-root-1 {
  margin-left: 0px !important;
}

.MuiListItem-gutters {
  padding-left: 0px !important;
}

.MuiTypography-body1 {
  font-size: 12px !important;
  color: gray !important;
}

.MuiTypography-body2 {
  font-size: 12px !important;
  color: gray !important;
}

.chip-body {
  height: 34px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--label-color) !important;
}

.css-1rhbuit-multiValue {
  background-color: var(--label-color) !important;
  color: white;
}

.css-12jo7m5 {
  color: white !important;
}

#event-calender-panel {
  height: 306px;
  margin-top: 32px;
  margin-left: 323px;
  width: 355px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(197, 195, 195);
}

#form-panel {
  max-height: 100%;
  height: 100%;
  width: 98%;
  overflow-y: auto;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
  margin-top: 10px;
  font-weight: 200px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(197, 195, 195);
}

#event-detials-panel {
  margin-top: 1px;
  margin-left: 323px;
  width: 355px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(197, 195, 195);
}

.event-detials-container label {
  font-size: 10px !important;
  margin-left: 10px;
  margin-right: 10px;
  color: gray !important;
}

.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
}

.errorMessagetype {
  color: red;
  font-size: 0.75em;
  display: relative;
  margin-left: 153px;
  margin-top: 20px;
}

.errorMessagedate {
  color: red;
  font-size: 0.75em;
  display: relative;
  margin-left: 142px;
  /* margin-top: 20px; */
}

.errorMessageService {
  color: red;
  font-size: 0.75em;
  display: relative;
  margin-top: 22px;
  margin-left: -41px;
}

.errorMessageAsset {
  color: red;
  font-size: 0.75em;
  display: relative;
  margin-top: 32px;
  margin-left: -41px;
}

.custom-dev {
  padding-top: 15px;
  padding-left: 15px;
}

.main-event-planner {
  width: 100%;
  height: 100%;
  background: #DCDCDC;
  margin-top: 90px;
  position: relative;
  margin-bottom: -10px;
}

.main-event-planner label {
  font-size: 12px;
  color: gray;
}

.personel-div {
  border: 1px solid lightgray;
  height: 40px;
  width: 90px !important;
  align-content: center;
  margin-top: 16px;
}

.personel-div button {
  margin-top: 5px;
  font-size: 10px;
  margin-left: 56px !important;
}

.save-btn {
  font-size: 12px;
  width: 146px;
}

.subject-info-box-1, .subject-info-box-2 {
  float: left;
  width: 45%;
}

.subject-info-box-2 select {
  height: 200px;
  padding: 0;
}

.subject-info-box-2 select option {
  padding: 4px 10px 4px 10px;
}

.subject-info-box-2 select option:hover {
  background: #EEEEEE;
}

.subject-info-arrows {
  float: left;
  width: 10%;
}

.subject-info-arrows input {
  width: 70%;
  margin-bottom: 5px;
}

.asset-list {
  width: 100% !important;
}
.mui_list{
  width: 100% !important;
}

.category {
  font-size: 8px;
  margin-left: 4px;
}

.ec-enddate-div {
  margin-top: 30px;
}