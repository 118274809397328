.MuiTypography-h5 {
  font-size: 17px !important;
}

.fb-Card {
  margin-bottom: 50px;
}

#formbuilderSidenav a {
  position: absolute;
  left: -50px;
  transition: 0.3s;
  padding: 10px;
  height: 36px;
  width: 89px;
  text-decoration: none;
  font-size: 10px;
  color: white;
  border-radius: 0 5px 5px 0;
}

#formbuilderSidenav a:hover {
  left: 0;
}

#formback{
  top: 11px;
  background: var(--theme-background-color);
}

.question_title {
  margin-left: 45px !important;
}
.form_adjustment {
  padding: inherit;
  padding: 4px;
}
.ul_form {
  height: 500px;
  overflow-y: scroll;
  width: 100%;
}
.divider_left {
  height: 1px;
  background-color: var(--bg);
}

.divider_right {
  height: 1px;
  background-color: var(--bg);
  margin-top: 31px;
}
.icon_space{
  margin: 4px;
}