.main-event-calendar {
    width : 100%;
    height: 100%;
    background: #DCDCDC	;
    margin-top : 80px;
    position : relative;
    margin-bottom: -10px;
    overflow: hidden;
    
}
.event-type-calendar-row{
    margin-left: 0px;
    margin-top: 20px;
}
.react-calendar {
    width: 313px !important;
    max-width: 100%;
    background: white;
    border: 0px !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.body-row{

  margin:4px;
}
.radio-event-type{
        margin-top: 18px;
        margin-left: 8px;
}
.radio-event-type label{
    font-size: 14px;
}
.heading-row{

    margin-bottom:15px;
}
.btn-calendar-custom{
   position: absolute;
   left: 0;  
   margin-top: 32px;
   width: 116px;
   margin-left: 30px;

}
.heading {
    margin-left: 40px;
    margin-top: 20px

}
#event-calendar-maincard-left {
    max-height: 500px;
    height: 500px;
    max-width: 316px;
    margin-right: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-left: 20px; */
    /*margin-bottom: 15px;
    margin-top: 10px;*/
    /* margin-right: 10px; */
    font-weight: 200px
}
#event-calendar-maincard-right {
    max-height:500px;
    height:500px;
    /* width: 1500px; */
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-right: 30px; */
    /* margin-left: 20px; */
    /* margin-left: 40px;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-right: 40px; */
    font-weight: 200px
}
.btn-custom {
    width: 150px;
    margin-top: 20px;
    margin-bottom: 5px;
}
#right-calendar {
    margin-left: 162px
}
#left-calendar {
    margin-left: 222px
}

.fc-button-group > .fc-button:not(:last-child) {
    margin: 4px !important;
    background: var(--theme-button-color)  !important;
    border: none !important;
    font-size: 12px !important;
}
.fc-button-group > .fc-button:not(:first-child) {
    margin: 4px !important;
    background: var(--theme-button-color)  !important;
    border: none !important;
    font-size: 12px !important;
}
.fc-button-primary {
    color: #fff;
    background-color: var(--theme-button-color);
    border-color: var(--theme-button-color);
    margin: 4px !important;
    font-size: 12px !important;
}
.fc-button-primary:disabled {
    color: #fff;
    /* background-color: #4285f4;
    border-color: #4285f4; */
    background: var(--theme-button-disable-color);
    border-color: var(--theme-button-disable-color) ;
    margin: 4px !important;
    font-size: 12px !important;
}