

.fp-main-container{
  margin-top: 100px;
  margin-left: 8px;
}

/* Style buttons */
.fp-btn {
    border: none;
    color: white;
    padding: 2px 12px;
    font-size: 12px;
    float: right;
    height: 32px;
    margin-top: 8px;
  }
  
  /* Darker background on mouse-over */
  /* .fp-btn:hover {
    background-color: RoyalBlue;
  } */
  .fp-header{
      width: 100%;
  }
  .fp-input-search{
      background:  #9d9d9d4a !important;
      float: left;
     
  }
  .workFlowButton {
    border: none;
    color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  width: 200px;
  margin-right: 10px;
  margin-top: 8px;

  }

 .search-btn{
  margin-left: 45px;
 }
 .rm-paadding {
  padding: 0px !important;
}