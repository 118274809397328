.pd-main-container{
  margin-top: 153px;
  margin-left: 8px;
}

.pd-lb{
    border: none;
    color: blue;
    font-size: 14px;
    float: left;
    margin-left: 105px
  }
  .right-space{
      color: var(--label-color);
  }
  .name-font{
    font-size: large;
    font-weight: bold;
    margin-left: 4px;
  }
  .vl {
    border-left: 1px solid grey;
    height: 55px;
    margin-left: 100px;
    margin-top: -40px
  }
  .general-act-btn{
    margin-top: 10px;
    margin-left: 190px
  }
  .workflowDisplay {
    margin-right: 10px;
    border-radius: 25px
  }
  .icon-padding{
    padding-right: 25px;
  }

  .s-font{
      font-size: 12px;
      margin-left: 90px;
  }
  
  .m-font{
    font-size: 16px;
}
  
.l-font{
    font-size: 24px;
}
.pd-row-space{
    margin: 20px;
}
.typography-space{
    margin-top: 10px;
}


  
  #action {
    top: 20px;
    background: var(--theme-background-color)
  }

  #back{
    top: 80px;
    background:var(--theme-background-color)
  }

  .gradient{
    color: var(--theme-secondary-color);
  }
  /* .modal-header{
    background:var(--theme-background-color);
    color: white;
  } */
  .pd-actions-list{
    cursor: pointer;
    margin: 10px;
  }
  .pd-actions-btn{
    cursor: pointer;
  }
  /* .modal-body{
    height: 250px;
    overflow-y: auto;
} */
/* 
@media (min-height: 500px) {
    .modal-body { height: 400px; }
}

@media (min-height: 800px) {
    .modal-body { height: 600px;
    }
} */
