@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.user-main-header {
    width: 100%;
    margin-top: 0px;
    margin-left : 0px;
    margin-right : 0px;
    overflow: hidden

}
.text-muted {
    font-size: 20px !important;
    font-weight: bold !important;
}
.user-heading {
    margin-left: -90px;
    margin-top: -30px
}

.user-main-card {
    margin-left: 5px;
    margin-right: 15px;
    margin-top: 5px;
    width: 100%;
    height: 490px;
    overflow: hidden;
}
.btn-user {
    margin-top: 0px;
    margin-left: 230px
}
.user-date-picker {
    width: 300px;
    margin-left: 0px
  }

.user-select-dropdown {
    width: 300px;
}