.heading_ {
  position: relative;
  font-family: Lato;
  font-weight: 200;
  display: inline-block;
  color: #222;
  font-size: 24px;
  /* margin-top: 30px; */
  /* margin-left: 114px; */
  /* border-bottom: solid 2px var(--theme-secondary-color); */
  padding: 0 10px;
  align-self: end;
}

.radio_ {
  color: var(--bg) !important;
}
.error{
  color: red;
}
.extra_padding_margin_right{
  margin-right: 0;
  padding-right:2;
}
.extra_padding_margin_left{
  margin-left: 0;
  padding-left:2;
}
.font_adjust{
  color: #222 !important;
  font-weight: bold !important;
}