.reports-main-header {
    width: 100%;
    margin-top: 60px;
    margin-left : 10px;
    margin-right : 0px;
    overflow: hidden

}
.reports-heading {
    margin-top: -10px;
    margin-left: -70px
}
.reports-main-card {
    margin-left: 5px;
    margin-right: 15px;
    margin-top: 5px;
    width: 100%;
    height: 490px;
    overflow-y: scroll;
    overflow-x: hidden
}


.inner-card {
    height: 370px;
    overflow-y: auto;
    overflow-x: hidden
}

.reports-date-picker {
    width: 200px;
    margin-left: -35px
  }
.reports-select-dropdown {
    width:200px;
    margin-left: 15px
}

.filter-label{
    margin-left: 5px;
    display: inline;
}
.filter-location-label {
    margin-left: -10px;
    display: inline
}
.dynamic-filter-label {
    margin-left: 25px
}
.filter {
    width:200px;
    display: inline-block
}
.btn-add-report {
 margin-left: 300px;
 margin-top: 30px   
}