.main {
    margin-top: 80px;
}
.formLink {
    display: inline-block; 
    width: 100%; 
    color: white;
    font-size: 1.10em;
    margin-left: 32px;
    
  }
  .formLink:hover{
      color: var(--theme-secondary-color);
  }
