.sl-main-header {
    width: 100%;
    margin-top: 50px;
    margin-left : 5px;
    margin-right : 5px;
    overflow: hidden

}
.btn-add-sample {
    margin-top: 35px;
    margin-left: 700px
}
.sl-main-card {
    margin-left: 5px;
    margin-right: 15px;
    margin-top: 5px;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.sl-input-search {
    background: white !important;
    width: 25px
    

}
.sl-select-dropdown {
    width:300px
}
.date-picker {
    margin-left: 2px;
    width : 300px;
}
.search-btn{
    margin-left: 45px;
    width: 250px

}